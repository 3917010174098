import React from 'react';
import styled, { css } from 'styled-components';

import { fromScreen } from 'utils/media-query/responsive.util';
import { deviceBreakpoints } from 'utils/hooks/useDeviceQuery.hook';
import { colorsV2 } from 'style/colors-v2';

export const withHomePageSection =
  (Component, sectionProps = {}, containerProps = {}) =>
  props => {
    return (
      <Section {...sectionProps}>
        <Container {...containerProps}>
          <Component {...props} />
        </Container>
      </Section>
    );
  };

export const Section = styled.section.attrs({ className: 'section' })`
  position: relative;
  ${({ selfish }) =>
    selfish &&
    css`
      padding-top: 26px;
      padding-bottom: 26px;
    `}

  ${fromScreen(776)} {
    ${({ selfish }) =>
      selfish &&
      css`
        padding-top: 32px;
        padding-bottom: 32px;
      `}
  }
`;

const Container = styled.article`
  padding: 0 16px;
  margin: 0 auto;

  ${fromScreen(458)} {
    max-width: 457px;
  }

  ${fromScreen(776)} {
    max-width: 712px;
    padding: 0px;
  }

  ${fromScreen(1144)} {
    max-width: 1080px;
    padding: 0px;
  }

  img {
    max-width: 100%;
  }

  video {
    max-width: 100%;
    outline: none;
  }
`;
